.footer {
  background-color: #292929;
  color: white;
  font-size: 15px;

  .mr-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
