.h1-contacts {
  font-size: 65px !important;
}

.inline-a-foo {
  display: inline-flex;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.map {
  background-color: $primary;
  color: $secondary;
  min-height: 100vh;
  overflow: visible;

  p {
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
  }

  .frame-map {
    border: 8px solid white;
    width: 100%;
    min-height: 100vh;
    position: absolute;
    top: 4rem;
  }
}

.spacer-maps {
  background-color: white;
  height: 30vh;
  z-index: 10;
  @include media-breakpoint-down(md) {
    height: 100vh;
  }
}

@keyframes blink {
  0% {
    color: $primary;
  }
  100% {
    color: $grey;
  }
}

@-webkit-keyframes blink {
  0% {
    color: $primary;
  }
  100% {
    color: $grey;
  }
}

.blink {
  -webkit-animation: blink 5s linear infinite;
  -moz-animation: blink 5s linear infinite;
  animation: blink 5s linear infinite;

  &:hover {
    font-weight: bold;
    color: $primary !important;

  }
}

.contact-form {
  background-color: $secondary;
  color: $primary;
}
