.header {

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  .container, {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}

.title-orzi {
  background-color: #292b33;
  width: 100%;
  h1 {
    color: white;
  }
}

