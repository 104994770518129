body {
  font-family: 'ProximaNovaLight';
  -webkit-font-smoothing: antialiased;
  color: black;
  font-size: 17px;
  overflow-x: hidden;

  @include media-breakpoint-down(md) {
    font-size: 15px;
  }
}

hr {
  width: 100%;
  border-color: white;
}

.v-align-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.site {
  overflow-x: hidden;
}

.d-flex {
  flex-direction: column;
}

a {
  color: unset;

  &:hover {
    text-decoration: none !important;
    color: white;
  }
}


html {
  scroll-behavior: smooth;
}

h1 {
  color: white;
  font-family: 'Dancing Script', cursive;
  font-size: 40px;

  @include media-breakpoint-down(md) {
    font-size: 30px;
  }
}

h2 {
  color: #669933;
  //font-weight: bold;
  font-family: 'Dancing Script', cursive;
  font-size: 28px;
  @include media-breakpoint-down(md) {
    font-size: 25px;
  }
}

h3 {
  font-family: 'Dancing Script', cursive;
  font-size: 20px;
}

.section {
  min-height: 100vh;
}

.row {
  margin-left: 0;
  margin-right: 0;
}


.btn, .wpcf7-submit {
  padding: 1rem 2rem;
  color: black !important;
  text-transform: uppercase;
  background-color: white;
  font-family: NexaRust;
  letter-spacing: 0.7px;
  border: none !important;

  &:hover {
    color: white !important;
    background-color: black !important;
  }
}

.wpcf7-submit {
  height: unset !important;
  padding: 10px 20px !important;
}


.disappear {
  opacity: 0;
  transition: opacity 2s;

  &.appear {
    opacity: 1;
  }
}


.wpcf7 {
  .wpcf7-text, textarea {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #707070 !important;
    border-radius: 0 !important;
  }
}
