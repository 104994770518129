// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.

$primary: #59BE49;
$secondary: #5D5D5C;
$grey: #a7a7a7;

$form-check-input-width: 2em;

$form-check-input-border-radius: 50%;

// Personalizzazione dimensione container
$container-max-widths: (md: 1300px) !default;

@font-face {
  font-family: ProximaNovaLight;
  src: url("../fonts/ProximaNovaFont.otf") format("opentype");
}

@font-face {
  font-family: Rust;
  src: url("../fonts/IntroRustG-Base2Line.otf") format("opentype");
}

@font-face {
  font-family: NexaRust;
  src: url("../fonts/NexaRustSans-Trial-Black2.ttf") format("opentype");
}


