#sticky-nav {
  position: sticky;
  z-index: 100;
  top: 0;
  @include media-breakpoint-down(md) {
    position: fixed;
  }
}

.menu-item {
  position: relative;

  a {
    &::after {
      content: '';
      position: absolute;
      margin-top: 0.2rem;
      display: block;
      background-color: #669933;
      width: 0%;
      min-width: 0;
      height: 4px;
      transition: all 0.5s;
    }
  }

  &.menu-item-has-children {
    a {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 8px;
        display: block;
        background-color: unset;
        width: unset;
        min-width: 0;
        height: 4px;
      }
    }

    &:hover {
      a {
        &::after {
          width: unset;
          min-width: unset;
        }
      }
    }
  }

  &:hover {
    a {
      &::after {
        width: 35%;
        min-width: 35px;
      }
    }
  }
}

.page-template-page-bricchette {
  #wrapper-navbar {
    background-color: #292929;
    color: white;
  }

  a {
    color: white !important;
  }
}

.has-scroll-smooth {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@include media-breakpoint-down(md) {
  #navbarNavDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    background-color: white;
  }

  .navbar-dark {
    #navbarNavDropdown {
      background-color: #292929;
    }
  }

  .navbar {
    position: unset;
    padding: 0 !important;
  }

  .menu-item {
    margin-bottom: 1.5rem;
  }
}

#wrapper-navbar {
  background-color: white;
  color: black;

  .menu-item {
    padding: 0 1.2rem;

    a {
      color: black;
      font-size: 16px;
      padding: 0;
    }
  }

  .current-menu-item {
    a {
      &::after {
        content: '';
        position: absolute;
        margin-top: 0.2rem;
        display: block;
        background-color: #669933;
        width: 35%;
        min-width: 35px;
        height: 4px;
      }

      &.dropdown-item {
        &::after {
          top: unset;
          bottom: -1px;
          left: 15px;
        }
      }
    }
  }


  .logo-site {
    height: 60px;
    object-fit: contain;
    @include media-breakpoint-down(sm) {
      height: 70px;
    }
  }
}

/**
 * Forces sub-sub menu's
 * to display when sub menu
 * is displayed!
 */

.dropdown-menu {
  &:hover {
    ul {
      display: inline-grid;
    }
  }

  @include media-breakpoint-down(sm) {
    ul {
      display: inline-grid;
      margin-bottom: 0;
    }
  }
}

.dropdown-menu {
  border-radius: 0;


  @include media-breakpoint-up(md) {
    .dropdown-menu {
      left: 100%;
      top: 0;
    }
  }

  @include media-breakpoint-down(sd) {
    border: none;

    .dropdown-menu {
      .menu-item {
        margin-bottom: 0rem;
      }
    }
  }
}
