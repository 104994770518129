.swiper {
  width: 100%;
  height: 60vh;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background-size: cover;
  background-position: center;
}

.gallery {
  background-color: #292929;
  background-repeat: no-repeat;
  background-position: left -150px bottom -50px;
  background-size: 450px auto;
}
